/** Theme Profile **/

@media (prefers-color-scheme: light) {
  :root {
    --color1: #FFBDB8;
    --color2: #DEEBFF;
    --color3: #F2F2F2;
    --color4: #FFFFFF;
    --color5: #1C1C1C;
    --color6: #B2B2B2;
    --color7: #FFD597;
    --color8: #74CCA0;
    --menu-height: 65px;
    --padding: 44px;
    --address-grid-gap: 44px;
    --grid-gap: 20px;
    --big-title: 107pt;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --color1: #FFBDB8;
    --color2: #DEEBFF;
    --color3: #F2F2F2;
    --color4: #FFFFFF;
    --color5: #1C1C1C;
    --color6: #B2B2B2;
    --color7: #FFD597;
    --color8: #74CCA0;
    --menu-height: 65px;
    --padding: 44px;
    --address-grid-gap: 44px;
    --grid-gap: 20px;
    --big-title: 107pt;
  }
}

@media screen and (max-width: 1025px) {
  :root {
    --big-title: 70pt;
    --padding: 22px;
  }
}

/**
Font Set
*/


@font-face {
  font-family: 'Assistant';
  font-weight: 600;
  src: url('../assets/fonts/assistant-bold.ttf') format('truetype')
}

@font-face {
  font-family: 'Assistant';
  font-weight: 800;
  src: url('../assets/fonts/assistant-extrabold.ttf') format('truetype')
}

@font-face {
  font-family: 'Assistant';
  font-weight: 100;
  src: url('../assets/fonts/assistant-extralight.ttf') format('truetype')
}

@font-face {
  font-family: 'Assistant';
  font-weight: 200;
  src: url('../assets/fonts/assistant-light.ttf') format('truetype')
}

@font-face {
  font-family: 'Assistant';
  font-weight: 300;
  src: url('../assets/fonts/assistant-medium.ttf') format('truetype')
}

@font-face {
  font-family: 'Assistant';
  font-weight: 400;
  src: url('../assets/fonts/assistant-regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Assistant';
  font-weight: 500;
  src: url('../assets/fonts/assistant-semibold.ttf') format('truetype')
}

@font-face {
  font-family: 'Assistant';
  font-weight: 700;
  src: url('../assets/fonts/assistant-variablefont_wght.ttf') format('truetype')
}

@font-face {
  font-family: 'Caladea';
  font-weight: 600;
  src: url('../assets/fonts/caladea-bold.ttf') format('truetype')
}

@font-face {
  font-family: 'Caladea';
  font-weight: 700;
  font-style: italic;
  src: url('../assets/fonts/caladea-bolditalic.ttf') format('truetype')
}

@font-face {
  font-family: 'Caladea';
  font-weight: 300;
  src: url('../assets/fonts/caladea-italic.ttf') format('truetype')
}

@font-face {
  font-family: 'Caladea';
  font-weight: 400;
  src: url('../assets/fonts/caladea-regular.ttf') format('truetype')
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--color4);
  font-family: 'Assistant';
  font-weight: normal;
  font-size: 11pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
}

/** Components Style **/

.hide {
  display: none;
}

/** Font Styles **/
.big-title {
  color: var(--color1);
  font-family: 'Caladea';
  font-weight: 400;
  font-size: var(--big-title);
}

.big-title-small {
  font-size: 50pt !important;
}

@media screen and (max-width: 1025px) {
  .big-title-small {
    font-size: 50pt !important;
  }
}

.black-font {
  color: var(--color5) !important;
}

.white-font {
  color: var(--color4) !important;
}

.uppercase {
  text-transform: uppercase;
}

.padding-top {
  margin-top: var(--padding);
}

.footer-email {
  margin-left: 5px;
  font-family: 'Caladea';
  font-weight: 400;
  font-size: 21pt;
  cursor: pointer;
}

.footer-address-title {
  font-family: 'Caladea';
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 21pt;
}

.description {
  line-height: 25pt;
}

.button-action {
  text-transform: uppercase;
  font-family: 'Assistant';
  font-weight: 600;
  font-size: 11pt;
}

/** Screen **/
.container {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  /*scroll-snap-type: y proximity;*/
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
}

section {
  margin: 0;
  padding-top: var(--menu-height);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-bottom: 0;
  /*scroll-snap-align: start;*/
  min-height: 100vh;
}

.blue-background {
  background: var(--color2) !important;
}

section:last-child {
  background-color: var(--color1);
}

/** Grid Container Layer **/
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: 0;
}

.grid-content-view {
  grid-row: 1;
  grid-column: 1;
}

.grid-image-view {
  grid-row: 1;
  grid-column: 2 / span 2;
}

@media screen and (max-width: 1025px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0;
    grid-row-gap: var(--grid-gap);
  }

  .grid-container-hide {
    grid-column-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: var(--grid-gap);
  }

  .grid-content-view {
    grid-row: 1;
    grid-column: 1;
  }

  .grid-image-view {
    grid-row: 2;
    grid-column: 1;
  }
}

/** FlexBox Layer **/
.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: calc(4.5 * var(--grid-gap));
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - var(--menu-height));
}

.flex-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: calc(4.5 * var(--grid-gap));
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1025px) {
  .flex-row {
    flex-direction: column;
  }
}


/** Gallery Layer **/
.gallery-container-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: 0;
  min-height: calc(100vh - var(--menu-height));
}

.gallery-container-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: 0;
  min-height: calc(100vh - var(--menu-height));
}

.gallery-container-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: 0;
  min-height: calc(100vh - var(--menu-height));
}

.gallery-item1 {
  margin: auto;
  grid-row: 1;
  grid-column: 1;
}

.gallery-item2 {
  margin: auto;
  grid-row: 1;
  grid-column: 2;
}

.gallery-item3 {
  margin: auto;
  grid-row: 1;
  grid-column: 3;
}

.gallery-item4 {
  margin: auto;
  grid-row: 1;
  grid-column: 4;
}

@media screen and (max-width: 1025px) {
  .gallery-container-4 {
    grid-column-gap: 0;
    grid-row-gap: var(--grid-gap);
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }

  .gallery-container-3 {
    grid-column-gap: 0;
    grid-row-gap: var(--grid-gap);
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

  .gallery-container-2 {
    grid-column-gap: 0;
    grid-row-gap: var(--grid-gap);
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }

  .gallery-item1 {
    grid-row: 1;
    grid-column: 1;
  }

  .gallery-item2 {
    grid-row: 2;
    grid-column: 1;
  }

  .gallery-item3 {
    grid-row: 3;
    grid-column: 1;
  }

  .gallery-item4 {
    grid-row: 4;
    grid-column: 1;
  }
}

/** Careers Layer **/

.job {
  width: calc(100vw - calc(2 * var(--padding)));
  /*max-width: 1400px;*/
  min-height: calc(100vh - var(--menu-height));
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color2);
}

.job-details-content.active {
  opacity: 1;
  display: flex;
  flex-direction: row;
  transition: opacity 1s ease-out;
  justify-content: space-between;
}

@media screen and (max-width: 1025px) {
  .job-details-content.active {
    flex-direction: column;
  }

  .job-button-holder {
    justify-content: center;
  }
}

.job-details-content {
  display: none;
  flex-direction: row;
  opacity: 0;
}

.job-description {
  margin-top: 18px;
}

.jd-holder {
  margin: var(--padding);
  width: 1025px;
  /*margin-top: var(--padding);*/
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.no-job {
  text-transform: uppercase;
  font-weight: 600;
  flex-grow: 1;
  position: relative;
}

.no-job span {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@media screen and (max-width: 1025px) {
  .jd-holder {
    width: 80%;
    margin-left: 10%;
  }
}

.job-details {
  width: 100%;
  border-bottom: 1px solid var(--color5);
  padding-bottom: 25px;
}

.job-action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 18px;
}

.job-title {
  font-family: 'Assistant';
  padding-top: 3px;
  font-size: 11pt;
  font-weight: 600;
}

.job-location {
  display: flex;
  gap: 10px;
}

.job-location-title {
  padding-top: 3px;
}

.job-action-button {
  font-size: 15pt;
  font-weight: 400;
}

.job-button-holder {
  display: flex;
}

.apply-button {
  width: 120px;
  background: var(--color8);
  color: var(--color4);
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Assistant';
  font-size: 11pt;
  border: none;
  margin-top: auto;
  margin-bottom: 18px;
}

.filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  /*justify-content: space-between;*/
  font-size: 10pt;
  font-weight: 600;
  padding-top: 65px;
}

@media screen and (max-width: 1025px) {
  .filters {
    flex-direction: column;
  }
}

.filter-holder-text {
  padding: 10px;
}

.filter-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  font-family: 'Caladea';
  font-weight: 400;
  font-size: 21pt;
}

/** Dropdown CSS **/

.custom-dropdown-menu {
  margin-left: 15px;
  position: relative;
}

.custom-dropdown-menu-selected {
  background-color: var(--color1);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.custom-dropdown-menu-selected>span:first-of-type {
  width: 160px;
}

.custom-dropdown-menu-items {
  display: none;
  padding: 10px;
  position: absolute;
  top: 50px;
  z-index: 5;
}

.custom-dropdown-menu-items.active {
  display: block;
}

.custom-dropdown-menu-item.active {
  background-color: var(--color2);
}

.custom-dropdown-menu-item {
  background: var(--color4);
  padding: 10px;
  border: 1px solid var(--color5);
}

.arrow-up {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 20px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid var(--color4);
}

.arrow-down {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 20px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid var(--color4);
}


/** Footer Layer **/
.footer-layer {
  min-height: calc(100vh - var(--menu-height));
  width: calc(100vw - calc(2 * var(--padding)));
  align-items: flex-end;
  display: flex;
}

.footer-content {
  width: calc(100vw - calc(2 * var(--padding)));
}

.footer-message {
  display: block;
}

.footer-link {
  text-transform: uppercase;
  margin-right: 29px;
}

.footer-link:last-child {
  margin-right: 0px;
}

.footer-spliter {
  margin-top: var(--padding);
  margin-bottom: var(--grid-gap);
  border-bottom: 1px solid var(--color5);
}

.footer-status {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: calc(2*var(--grid-gap));
}

@media screen and (max-width: 1025px) {
  .footer-status {
    flex-direction: column;
    gap: 10px;
  }
}

.address-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: calc(2 * var(--address-grid-gap));
  grid-row-gap: 0;
  margin-top: var(--padding);
}

.grid-address-container-content {
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: var(--address-grid-gap);
  grid-row-gap: 0;
}

.address-grid-content-view {
  grid-row: 1;
  grid-column: 1;
}

.address-grid-image-view {
  grid-row: 1;
  grid-column: 2;
}

@media screen and (max-width: 1025px) {
  .address-grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0;
    grid-row-gap: var(--grid-gap);
  }

  .grid-address-container-content {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0;
    grid-row-gap: var(--grid-gap);
  }

  .address-grid-content-view {
    grid-row: 1;
    grid-column: 1;
  }

  .address-grid-image-view {
    grid-row: 2;
    grid-column: 1;
  }
}


/** Main Layer **/
.main-layer {
  padding-top: calc(3*var(--menu-height));
}

.less-margin-top {
  padding-top: var(--menu-height);
}

@media screen and (max-width: 1025px) {
  .less-margin-top {
    padding-top: calc(var(--menu-height) / 2);
  }
}

/** Product Layer **/
.right-box {
  background: linear-gradient(to right, var(--color4) 0, var(--color4) 22%, var(--color7) 22%, var(--color7) 100%, var(--color7));
  width: 100%;
}

.left-box {
  background: linear-gradient(to right, var(--color8) 0%, var(--color8) 56%, var(--color4) 56%);
  width: 100%;
}

.demo-button {
  padding: 10px 15px;
  border: none;
  margin-top: auto;
}

.right-demo-button {
  background: var(--color7);
  color: var(--color5);
}

.left-demo-button {
  background: var(--color8);
  color: var(--color4);
}

/** Team Layer **/


/** Menu **/
.menubar {
  padding-bottom: 17px;
  padding-top: 22px;
  padding-left: var(--padding);
  padding-right: var(--padding);
  background-color: var(--color4);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
}

.topnav {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: calc(100vw - calc(2 * var(--padding)));
  padding-right: var(--padding);
  justify-content: right;
}

.topnav span {
  display: block;
  color: var(--color6);
  text-align: center;
  text-decoration: none;
  font-family: 'Caladea';
  font-size: 15pt;
  font-weight: 400;
  margin-right: var(--padding);
  border-bottom: 1px solid var(--color6);
}

.topnav span:nth-last-child(2) {
  margin-right: 0px;
}

.topnav span.active {
  color: var(--color5);
}

.topnav .icon {
  display: none;
  border-bottom: 0px;
}

.topnav .icon>img {
  width: 32px;
  height: 32px;
}



@media screen and (max-width: 1025px) {
  .topnav span {
    display: none;
  }

  .topnav span.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 1025px) {
  .topnav.responsive {
    position: relative;
    flex-direction: column;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: var(--padding);
    top: 0;
  }

  .topnav.responsive span {
    float: none;
    display: block;
    text-align: left;
    padding-top: 10px;
  }
}

/** Overlay Window **/
.dialog-window {
  width: 100vw;
  height: 100vh;
  background-color: var(--color4);
  position: absolute;
  top: 0;
  z-index: 100;
  overflow-y: scroll;
}

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}

.close:hover {
  opacity: 1;
}

.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.overlay-window {
  max-width: 80vw;
  margin: 5vh 10vw;
}


/** Privacy and Terms **/

.term-title,
.privacy-title {
  text-align: center;
  font-family: 'Assistant';
  font-size: 16pt;
  font-weight: 600;
}

.term-content-header,
.privacy-content-header {
  text-align: left;
  font-family: 'Assistant';
  font-size: 14pt;
  font-weight: 600;
}

.term-content,
.privacy-content {
  text-align: left;
  font-family: 'Assistant';
  font-size: 12pt;
  font-weight: 300;
}

/**
App Icon Set
*/

.footer-app-icons {
  width: 34px;
  height: 34px;
  margin-top: 15px;
  margin-right: 19px;
  margin-bottom: 30px;
}

.app-icons {
  display: inline-flex;
}

.app-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.facebook {
  background: url('../assets/images/app/facebook_icon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.instagram {
  background: url('../assets/images/app/instagram_icon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.linkedin {
  background: url('../assets/images/app/linkedin_icon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.medium {
  background: url('../assets/images/app/medium_icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.snapchat {
  background: url('../assets/images/app/snapchat_icon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.twitter {
  background: url('../assets/images/app/twitter_icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

/** Profiles Layer **/

.full-profile-warp {
  align-items: center;
  justify-content: center;
}

.full-profile {
  /*padding: calc(var(--padding) / 2);*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  background-color: var(--color4);
}

.profile {
  width: 150px;
  height: 150px;
}

.profile-rounded {
  border-radius: 150px/150px;
}

.profile-name {
  margin-top: 27px;
  text-align: center;
  font-family: 'Caladea';
  font-size: 21pt;
  font-weight: 400;
}

.profile-title {
  margin-top: 10px;
  text-transform: uppercase;
  text-align: center;
}

.profile-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-lines {
  /*width: 150px;*/
  margin: 0px 50px;
  display: block;
  text-align: left;
}

.krishnan {
  background: url('../assets/images/profile/krishnan.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.anu {
  background: url('../assets/images/profile/anu.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.monish {
  background: url('../assets/images/profile/monish.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.priyanka {
  background: url('../assets/images/profile/priyanka.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.nupoor {
  background: url('../assets/images/profile/nupoor.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.teja {
  background: url('../assets/images/profile/teja.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/** Tile Icons **/
.human_centered {
  background: url('../assets/images/tiles/human_centered.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.collab {
  background: url('../assets/images/tiles/icon_collab.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.growth {
  background: url('../assets/images/tiles/growth.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.zoom_in_out {
  background: url('../assets/images/tiles/zoom.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/** Background CSS **/

.tail-main-first-half {
  /*background: url('../assets/images/background/about_background.svg');*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: var(--menu-height);
  height: calc(100vh - var(--menu-height));
}

.tail-main-second-half {
  background: url('../assets/images/background/about_background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(100vh - var(--menu-height));
  background-position: right;
}

.home-main-first-half {
  background: url('../assets/images/background/main-first-half.svg');
  background-size: 400px;
  background-repeat: no-repeat;
  /*height: calc(100vh - var(--menu-height));*/
  background-position-y: -100px;
  /*background-position-x: calc(var(--menu-height) + 40px);
  background-position-y: 0;
  height: calc(100vh - var(--menu-height));*/
}

.home-main-second-half {
  background: url('../assets/images/background/main-second-half.svg');
  background-size: contain;
  background-repeat: no-repeat;
  /*height: calc((100vh - var(--menu-height)));*/
  /*background-position-x: calc(-5*var(--menu-height));*/
}

.amend-background {
  background: url('../assets/images/background/amend_background.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  /*height: calc(100vh - var(--menu-height));*/
}

.mobius-background {
  background: url('../assets/images/background/mobius_background.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  /*height: calc(100vh - var(--menu-height));*/
}

.local-office {
  background: url('../assets/images/location/chennai_office.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.us-office {
  background: url('../assets/images/location/us_office.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.white-background {
  margin-left: var(--padding);
  background-color: var(--color4);
  padding-left: calc(var(--padding) / 2);
  padding-bottom: calc(var(--padding) / 2);
  padding-right: calc(var(--padding) / 2);
}

@media screen and (max-width: 1025px) {
  .white-background {
    margin-left: 0;
  }
}

/*.grid-background {
  background-image:
      repeating-linear-gradient(var(--color6) 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, var(--color6) 0 1px, transparent 1px 100%);
  background-size: 30px 30px;
  background-repeat: repeat;
}*/

.blank-space {
  height: 50px;
}

@media screen and (max-width: 1025px) {
  .blank-space {
    height: 50px;
  }
}